import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { fetchData, validateForm } from '../../components/Helpers/Helper'
import { RESET_PASSWORD } from '../../components/Helpers/ApiRoutes';
import { UnAuthSection } from '../../components/Layouts/Layout';
import { UnAuthPasswordField, UnAuthSubmitButton } from '../../components/Helpers/FormHelper';
import { useTranslation } from "react-i18next"

const ResetPassword = () => {
    const { t } = useTranslation();
    const [tinyloader, setTinyloader] = useState(false);
    const navigate = useNavigate();
    const param = useParams();

    const submitForm = (e) => {
        if (validateForm(e)) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('reset-password-form'));
            formdata.append('token', param.token)
            formdata.append('email', param.email)
            fetchData(RESET_PASSWORD, 'POST', formdata, false, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    navigate('/login')
                }
            })
        }
    }

    return (
        <UnAuthSection>
            <form className="p-4 p-sm-5 w-100 needs-validation" noValidate id="reset-password-form">
                <h4 className="heading-lines mb-4 mt-0">{t("Reset Password")}</h4>
                <UnAuthPasswordField name="password" placeholder="********" required />
                <UnAuthPasswordField name="password_confirmation" placeholder="********" required />
                <UnAuthSubmitButton load={tinyloader} title={t("RESET PASSWORD")} action={submitForm} />
                <div className="d-flex justify-content-between pb-3">
                    <Link to="/login" className="anchor-blueorange fw-medium" title={t("Login")}>{t("Login")}</Link>
                </div>
            </form>
        </UnAuthSection>
    )
}

export default ResetPassword
