import { useState } from "react";


function ThemeColor() {
    const [theme, setTheme] = useState(localStorage.getItem('theme') ?? 'blue');
    const [css] = useState({
        teal: `:root {--blue: #008080; --blue-rgb: 0 128 128; --orange: #d2691e; --orange-rgb: 210 105 30; --grey: #edf2f9; --admin-bg: #fff; --card-bg: #fff; --header-rgb: #fff }`,
        tomato: `:root {--blue: #ff6347; --blue-rgb: 255 99 71; --orange: #8b134f; --orange-rgb: 139 19 79; --grey: #edf2f9; --admin-bg: #fff; --card-bg: #fff; --header-rgb: #fff }`,
        darkgoldenrod: `:root {--blue: #b8860b; --blue-rgb: 184 134 11; --orange: #d2691e; --orange-rgb: 210 105 30; --grey: #edf2f9; --admin-bg: #fff; --card-bg: #fff; --header-rgb: #fff }`,
        chocolate: `:root {--blue: #d2691e; --blue-rgb: 210 105 30; --orange: #5f9ea0; --orange-rgb: 95 158 160; --grey: #edf2f9; --admin-bg: #fff; --card-bg: #fff; --header-rgb: #fff }`,
        green: `:root {--blue: #133620; --blue-rgb: 19 54 32; --orange: #9acd32; --orange-rgb: 154 205 50; --grey: #edf2f9; --admin-bg: #fff; --card-bg: #fff; --header-rgb: #fff }`,
        blue: `:root {--blue: #324daa; --blue-rgb: 32, 188, 239; --orange: #41c9d6; --orange-rgb: 241, 88, 41; --grey: #edf2f9; --admin-bg: #fff; --card-bg: #fff; --header-rgb: #fff }`,
        aqua: `:root {--blue: #00ffff; --blue-rgb: 0 255 255; --orange: #324daa; --orange-rgb: 32, 188, 239; --grey: #edf2f9; --admin-bg: #fff; --card-bg: #fff; --header-rgb: #fff }`,
        brown: `:root {--blue: #a52a2a; --blue-rgb: 165 42 42; --orange: #361313; --orange-rgb: 74, 29, 29; --grey: #edf2f9; --admin-bg: #fff; --card-bg: #fff; --header-rgb: #fff }`,
        purple: `:root {--blue: #800080; --blue-rgb: 128 0 128; --orange: #5f9ea0; --orange-rgb: 95 158 160; --grey: #edf2f9; --admin-bg: #fff; --card-bg: #fff; --header-rgb: #fff }`,
        blueviolet: `:root {--blue: #8a2be2; --blue-rgb: 138 43 226; --orange: #800080; --orange-rgb: 128 0 128; --grey: #edf2f9; --admin-bg: #fff; --card-bg: #fff; --header-rgb: #fff }`,
        maroon: `:root { --blue: #361313; --blue-rgb: 74, 29, 29; --orange: #5b6d6e; --orange-rgb: 91 109 110; --grey: #b7b7b7; --admin-bg: #cdcdcd; --card-bg: #b7b7b7; --header-rgb: #d3d3d3 }`,
        black: `:root { --blue: #000000; --blue-rgb: 0,0,0; --orange: #5b6d6e; --orange-rgb: 91 109 110; --grey: #b7b7b7; --admin-bg: #cdcdcd; --card-bg: #b7b7b7; --header-rgb: #d3d3d3 }`,
    });

    const changeTheme = (color) => {
        localStorage.setItem('theme', color);
        setTheme(color);
    }

    return (<>
        <style jsx="true">{css[theme]}</style>
        <div className="dropdown dropdown-orange d-inline-block align-middle ms-2 rounded bg-blue">
            <button type="button" data-bs-toggle="dropdown" className="btn">
                <div>
                    <span className="mx-1 bg-blue"></span>
                </div>
            </button>
            <ul className="dropdown-menu dropdown-menu-end shadow border-0 p-2 bg-card">
                {['blue', 'maroon', 'green', 'brown', 'purple', 'black', 'aqua', 'blueviolet', 'chocolate', 'darkgoldenrod', 'tomato', 'teal'].map(color => {
                    return <li style={{ background: color }} key={color} className={`btn d-inline-flex dropdown-item rounded my-1px py-3`} onClick={() => changeTheme(color)}></li>
                })}
            </ul>
        </div>
    </>);
}

export default ThemeColor;