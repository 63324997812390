import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./components/Lang/en.json";
import hi from "./components/Lang/hi.json";
import ar from "./components/Lang/ar.json";
import cn from "./components/Lang/cn.json";
import it from "./components/Lang/it.json";

const resources = {
    en: { translation: en },
    hi: { translation: hi },
    ar: { translation: ar },
    cn: { translation: cn },
    it: { translation: it },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem('localization') ?? 'en',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;