import { useEffect, useState } from "react";
import { noImageHandle } from "../../../components/Helpers/Helper";
import { query, collection, onSnapshot, limit, where } from "firebase/firestore";
import { auth, db } from "./Initialize";

export const Users = ({ chatUser, setChatUser }) => {
    const [chatUsers, setChatUsers] = useState([]);

    useEffect(() => {
        const q = query(collection(db, "users"), where("uid", "!=", auth.currentUser.uid), limit(50));
        const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
            let users = [];
            QuerySnapshot.forEach((doc) => users.push({ ...doc.data(), id: doc.id }));
            setChatUsers(users);
        });
        return () => unsubscribe
    }, []);

    return (<>
        <div className="col-sm-4 py-2 border rounded">
            <div className="overflow-auto mmh-600px">
                {chatUsers.map((user, key) => {
                    return (<div key={key} className={`rounded border mb-1 p-1 ${(chatUser && user.uid === chatUser.uid) && 'bg-secondary'}`} role="button" onClick={() => setChatUser(user)}>
                        <img className="mmwh-35px" src={user.photoURL} alt="Prolfile logo" onError={noImageHandle} />
                        <span className="mx-1 my-2">{user.displayName}</span>
                    </div>)
                })}
            </div>
        </div>
    </>)
}