import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Context from "../Helpers/Context";
import en from "./en.json";
import hi from "./hi.json";
import ar from "./ar.json";
import cn from "./cn.json";
import it from "./it.json";

export const trans = (key) => {
    let lang = localStorage.getItem('localization') ?? 'en'

    if (lang === 'en') return en[key] ?? key
    else if (lang === 'hi') return hi[key] ?? key
    else if (lang === 'ar') return ar[key] ?? key
    else if (lang === 'cn') return cn[key] ?? key
    else if (lang === 'it') return it[key] ?? key
    else return key
}

function ChangeLanguage() {
    const [context] = useContext(Context);
    const [language, setLanguage] = useState(null);
    const [languages, setLanguages] = useState([]);
    const { i18n } = useTranslation();

    useEffect(() => {
        if (context && context.site && context.site.language) {
            setLanguage(context.site.language)
            setLanguage(context.site.language.filter(lang => lang.code === (localStorage.getItem('localization') ?? 'en'))[0])
        }
        setLanguages(context && context.site && context.site.language ? context.site.language : []);
    }, [context])

    const changeLanguageHandler = (lang) => {
        setLanguage(lang);
        i18n.changeLanguage(lang.code);
        localStorage.setItem('localization', lang.code);
    }
    return (
        <div className="dropdown dropdown-orange d-inline-block align-middle">
            {language && <button type="button" data-bs-toggle="dropdown" className="btn btn-outline-orange">
                <div>
                    <img className="mmwh-24px" src={process.env.REACT_APP_ASSET_URL+language.image} alt="logo" />
                    <span className="mx-1">{language.code}</span>
                </div>
            </button>}
            <ul className="dropdown-menu dropdown-menu-end shadow border-0 p-2 bg-card">
                {languages.map(lang => {
                    return <li key={lang.code} className={`btn d-inline-flex dropdown-item rounded my-1px ${lang.code === language.code && 'bg-blue text-white'}`} onClick={() => changeLanguageHandler(lang)}>
                        <img className="mmwh-24px" src={process.env.REACT_APP_ASSET_URL+lang.image} alt="logo" />
                        <span className="ms-2" title={lang.name}>{lang.name} ({lang.code})</span>
                    </li>
                })}
            </ul>
        </div>
    );
}

export default ChangeLanguage;