import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

export const EditButton = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" trigger={['click', 'focus', 'hover']} overlay={<Tooltip> {props.title ?? t('Edit')} </Tooltip>}>
            <button className="btn btn-sm btn-outline-secondary me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-pencil-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const DeleteButton = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? t('Delete')} </Tooltip>}>
            <button className="btn btn-sm btn-outline-danger me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-trash-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const ActiveButton = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? t('Activate')} </Tooltip>}>
            <button className="btn btn-sm btn-outline-success me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-check-circle-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const DeactiveButton = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? t('Deactivate')} </Tooltip>}>
            <button className="btn btn-sm btn-outline-warning me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-x-circle-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const VerifyButton = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? t('Email-verify')} </Tooltip>}>
            <button className="btn btn-sm btn-outline-success me-1 action-btn icon-white" onClick={props.action ?? null}>
                <img src="/images/user.svg" className="h-20px" alt="progress" />
            </button>
        </OverlayTrigger>
    )
}

export const CancelButton = (props) => {
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? 'Cancelled'} </Tooltip>}>
            <button className="btn btn-sm btn-outline-danger me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-x-circle-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const AddButtonModal = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? t('Add')} </Tooltip>}>
            <button id={props.id ?? null} className="btn btn-sm btn-outline-blue me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-plus-circle-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const EditButtonModal = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? t('Edit')} </Tooltip>}>
            <button className="btn btn-sm btn-outline-secondary me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-pencil-fill"></i>
            </button>
        </OverlayTrigger>
    )
}
