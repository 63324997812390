import { AuthSection } from "../../components/Layouts/Layout";
import ManagePage from "../../components/Layouts/ManagePage";
import { useTranslation } from "react-i18next"

const Dashboard = () => {
    const { t } = useTranslation();

    return (
        <AuthSection title={t("Dashboard")}>
            <ManagePage code={200} />
        </AuthSection>
    )
}

export default Dashboard