import { useLocation } from 'react-router';
import { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import Context from "../Helpers/Context";
import { useTranslation } from "react-i18next"

const Aside = () => {
    const { t } = useTranslation();
    const [context] = useContext(Context)
    const location = useLocation()

    const checkLocation = (url) => {
        return url.some((l) => location.pathname.match(l))
    }
    const checkPermission = (permission) => permission.some((p) => context && context.auth && context.auth.permissions && context.auth.permissions.includes(p))

    return (
        <aside className="admin-aside">
            <div className="admin-logobox">
                <Link to="/" className="admin-brandbox" title={t("Home")}>
                    <img src={context && context.site ? context.site.logo : 'images/logo.png'} alt={t("Logo")} />
                </Link>
                <button type="button" className="btn btn-close mmwh-36px border-0 p-0 align-items-center justify-content-center" id="menuClose" title={t("Menu Toggle")}></button>
                <hr />
            </div>

            <div className="admin-navbox" id="navtoggle">
                <div className="accordion-item">
                    <NavLink className={checkLocation(['dashboard']) ? "main-link active collapsed" : "main-link collapsed"} to="/dashboard" title={t("Dashboard")}>
                        <i className="bi-speedometer" role="img" aria-label="Speedometer"></i>
                        {t("Dashboard")}
                    </NavLink>
                </div>
                {context && context.auth && context.auth.permissions && <>
                    {checkPermission(['site-manage', 'role-manage', 'permission-manage', 'language-manage', 'file-manage']) && <>
                        <div className="accordion-item">
                            <a className={checkLocation(['site-manage', 'role-manage', 'permission-manage', 'language-manage', 'file-manage']) ? "main-link" : "main-link collapsed"} data-bs-toggle="collapse" href="#submenu1" aria-expanded={checkLocation(['site-manage', 'role-manage', 'permission-manage', 'language-manage', 'file-manage']) ? 'true' : 'false'} title={t("Settings")}>
                                <i className="bi-gear-wide-connected" role="img" aria-label="Person"></i>
                                {t("Settings")}
                            </a>
                            <div id="submenu1" className={checkLocation(['site-manage', 'role-manage', 'permission-manage', 'language-manage', 'file-manage']) ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'} data-bs-parent="#navtoggle">
                                {checkPermission(['site-manage']) && <NavLink className={checkLocation(['site-manage']) ? 'sub-link active' : 'sub-link'} to="/site-manage" title={t("Site Manage")}>{t("Site Manage")}</NavLink>}
                                {checkPermission(['role-manage']) && <NavLink className={checkLocation(['role-manage']) ? 'sub-link active' : 'sub-link'} to="/role-manage" title={t("Role Manage")}>{t("Role Manage")}</NavLink>}
                                {checkPermission(['permission-manage']) && <NavLink className={checkLocation(['permission-manage']) ? 'sub-link active' : 'sub-link'} to="/permission-manage" title={t("Permission Manage")}>{t("Permission Manage")}</NavLink>}
                                {checkPermission(['language-manage']) && <NavLink className={checkLocation(['language-manage']) ? 'sub-link active' : 'sub-link'} to="/language-manage" title={t("Language Manage")}>{t("Language Manage")}</NavLink>}
                                {checkPermission(['file-manage']) && <NavLink className={checkLocation(['file-manage']) ? 'sub-link active' : 'sub-link'} to="/file-manage" title={t("File Manage")}>{t("File Manage")}</NavLink>}
                            </div>
                        </div>
                    </>}
                    {checkPermission(['user-manage']) && <>
                        <div className="accordion-item">
                            <NavLink className={checkLocation(['user-list', 'user-create', 'user-edit']) ? "main-link active collapsed" : "main-link collapsed"} to="/user-list" title={t("User List")}>
                                <i className="bi-person-circle" role="img" aria-label="Speedometer"></i>
                                {t("User List")}
                            </NavLink>
                        </div>
                    </>}
                    {checkPermission(['chat-manage']) && <>
                        <div className="accordion-item">
                            <NavLink className={checkLocation(['chat']) ? "main-link active collapsed" : "main-link collapsed"} to="/chat" title={t("Chat")}>
                                <i className="bi-chat" role="img" aria-label="Speedometer"></i>
                                {t("Chat")}
                            </NavLink>
                        </div>
                    </>}
                </>}
            </div>
            <div className="admin-copyrightsbox">{context && context.site && context.site.copyright} | v1.0.0</div>
        </aside>
    )
}

export default Aside
