import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './components/Css/index.css'
import './i18n.js';
import Routing from './components/Routing';
import reportWebVitals from './reportWebVitals';
// import "./components/Css/color.css";


const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
    <>
        <Router>
            <Routing />
        </Router>
        <div className="position-fixed top-0 end-0 p-2 toast-end pointer-none" style={{ 'zIndex': '1111' }} id="toastContainer"></div>
    </>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
