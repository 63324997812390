import { useEffect, useState } from "react";
import DataTables, { reloadUrlDataTable } from "../../components/Helpers/Datatables";
import { fetchData, initialFormState, validateForm } from "../../components/Helpers/Helper";
import { AuthSection } from "../../components/Layouts/Layout";
import { ModalInputField, ModalSubmitButton } from "../../components/Helpers/FormHelper";
import { PERMISSIONS, PERMISSION_DELETE } from "../../components/Helpers/ApiRoutes";
import { now } from "lodash";
import { createRoot } from "react-dom/client";
import { EditButtonModal } from "../../components/Helpers/ButtonHelper";
import { DeleteButton } from "../../components/Helpers/ButtonHelper";
import { ConfirmationModal, ModalSection } from "../../components/Helpers/ModalHelper";
import { useTranslation } from "react-i18next"

const PermissionManage = () => {
    const { t } = useTranslation();
    const [permissionArr] = useState([])
    const [permission, setPermission] = useState(null)
    const [tinyloader, setTinyloader] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const [dt] = useState({
        dt_url: PERMISSIONS,
        dt_name: 'permission_list',
        dt_column: [
            { data: "id", name: 'id', title: "", sortable: false, searchable: false },
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false },
            { data: "name", name: 'name', title: "Name" },
            { data: "id", title: "Action", sortable: false, searchable: false, class: "text-nowrap noExport", width: 160 }
        ],
        dt_column_defs: [
            {
                targets: 0,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <input disabled={['permission-manage'].includes(records.name)} className="delete-permission" type="checkbox" name='permission_id[]' value={records.id} defaultChecked={permissionArr && permissionArr.includes(records.id)} onChange={checkPermission} />
                    )
                }
            },
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(!['permission-manage'].includes(records.name) && <>
                        <div className="d-flex text-nowrap">
                            <EditButtonModal modal_id="#createPermissionModal" action={() => editPermission(records.id)} />
                            <DeleteButton modal_id="#permissionDeleteModal" action={() => setPermission(records.id)} />
                        </div>
                    </>)
                }
            }
        ],
        dt_filter: () => {
            createRoot(document.getElementById("dt-custom-button")).render(
                <button type="button" id="deletePermission" className="btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target="#permissionDeleteModal" disabled>{t("Delete")}</button>
            )
        }
    })

    useEffect(() => {
        reloadUrlDataTable(dt, PERMISSIONS)
    }, [dt, refresh])

    const resetForm = () => {
        setPermission(null)
        initialFormState('create-permission-Form')
    }

    const submitForm = (e) => {
        if (validateForm(e)) {
            setTinyloader(true)
            let API_URL = permission ? `${PERMISSIONS}/${permission.id}` : PERMISSIONS
            let formdata = new FormData(document.getElementById('create-permission-Form'));
            fetchData(API_URL, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    document.getElementById('closePermissionModal').click()
                    setRefresh(now())
                }
            })
        }
    }

    const editPermission = (id) => {
        fetchData(`${PERMISSIONS}/${id}/edit`, 'GET', '', true, true, (res) => {
            if (res.success) {
                setPermission(res.data)
            }
        })
    }

    const deletePermission = () => {
        let API_URL = permissionArr.length ? `${PERMISSION_DELETE}?ids=${permissionArr}` : `${PERMISSIONS}/${permission}`
        fetchData(API_URL, 'DELETE', '', true, true, (res) => {
            if (res.success) {
                resetForm()
                setRefresh(now())
            }
        })
    }

    const checkPermission = (e) => {
        let val = parseInt(e.target.value)
        if (e.target.checked) {
            permissionArr.push(parseInt(val))
        } else {
            permissionArr.splice(permissionArr.indexOf(val), 1)
        }
        document.getElementById("deletePermission").disabled = permissionArr.length === 0
    }

    return (
        <AuthSection title="Permission Manage" btn={<button type="button" className="btn btn-blue btn-sm px-3 mb-1 me-2" data-bs-toggle="modal" data-bs-target="#createPermissionModal" title={t("Create Permission")}><i className="bi bi-plus-circle-fill me-1"></i> {t("Create Permission")}</button>}>
            <DataTables dt={dt} dt_name="permission_list" />
            <ModalSection title={t(`${permission ? "Edit" : "Add"} Permission`)} modal_id="createPermissionModal" size="lg" close_btn_id="closePermissionModal" action={resetForm}>
                <form action="#" method="post" className="needs-validation" id="create-permission-Form" noValidate>
                    <ModalInputField name="name" defaultValue={permission && permission.name ? permission.name : ""} required />
                    <ModalSubmitButton type="submit" load={tinyloader} title={t(permission ? "Update" : "Create")} action={submitForm} />
                </form>
            </ModalSection>
            <ConfirmationModal modal_id="permissionDeleteModal" title="Delete Permission" action={deletePermission} closeModal={resetForm}>
                <p className="">{t(`Are you sure want to delete ${permissionArr && permissionArr.length ? "this permission" : "selected permission"}?`)}</p>
            </ConfirmationModal>
        </AuthSection>
    )
}

export default PermissionManage