import { useState } from "react";
import { auth, db } from "./Initialize";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { trimStart } from "lodash";

export const SendMessage = ({ chatUser }) => {
    const [message, setMessage] = useState("");

    const sendMessage = async () => {
        const { uid, displayName, photoURL } = auth.currentUser;
        await addDoc(collection(db, "userChats"), { text: message, name: displayName, avatar: photoURL, createdAt: serverTimestamp(), uid: [uid, chatUser.uid], senderUid: uid, receiverUid: chatUser.uid });
        setMessage("");
        setTimeout(() => {
            let scroll = document.querySelector("#messagesList")
            scroll.scrollTop = scroll.scrollHeight;
            scroll.behavior = "smooth"
        }, 0);
    }

    return (<>
        <div className="input-group">
            <span className="input-group-text">Message</span>
            <textarea className="form-control" id="note" aria-label="With textarea" rows={1} placeholder="Write your messages ..." onChange={(e) => setMessage(trimStart(e.target.value))} value={message} />
            <button className="btn btn-sm btn-blue" onClick={sendMessage} title="Send" disabled={!message}><i className="bi bi-send-x-fill"></i></button>
        </div>
    </>)
}