import { useEffect, useState } from "react";
import { InputField, SubmitButton } from "../../components/Helpers/FormHelper";
import { AuthSection } from "../../components/Layouts/Layout";
import { fetchData, initialFormState, validateForm } from "../../components/Helpers/Helper";
import { ADD_NEW_SETTING, GET_SETTINGS, UPDATE_SETTINGS } from "../../components/Helpers/ApiRoutes";
import { now } from "lodash";
import { useTranslation } from "react-i18next"

const SiteManage = () => {
    const { t } = useTranslation();
    const [tinyloader, setTinyloader] = useState(false);
    const [tinyloader2, setTinyloader2] = useState(false);
    const [reload, setReload] = useState(false);
    const [data, setData] = useState([])

    useEffect(() => {
        fetchData(GET_SETTINGS, 'GET', '', true, false, (res) => {
            if (res.success) {
                setData(res.data)
            }
        })
    }, [reload])

    const submitForm = (e) => {
        if (validateForm(e, 'add-setting-form')) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('add-setting-form'))
            fetchData(ADD_NEW_SETTING, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    initialFormState("add-setting-form")
                    setReload(now())
                }
            })

        }
    }

    const updateForm = (e) => {
        if (validateForm(e, 'update-setting-form')) {
            setTinyloader2(true)
            let formdata = new FormData(document.getElementById('update-setting-form'))
            fetchData(UPDATE_SETTINGS, 'POST', formdata, true, true, (res) => {
                setTinyloader2(false)
            })
        }
    }


    return (
        <AuthSection title="Site Manage" can="site-manage">
            <form id="add-setting-form" action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                <fieldset className="border1 rounded-10 p-3 p-sm-4 mb-4 shadow">
                    <legend className="fs-6 fw-medium text-blue">{t("Add New Field")}</legend>
                    <InputField name="type" required />
                    <InputField name="name" required />
                    <InputField name="value" required />
                </fieldset>
                <SubmitButton noOffset load={tinyloader} title="SAVE" action={submitForm} />
            </form>
            <hr />
            <form id="update-setting-form" action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                {data.map((types) => {
                    return <fieldset key={types.value} className="border1 rounded-10 p-3 p-sm-4 mb-4 shadow">
                        <legend className="fs-6 fw-medium text-blue">{types.value}</legend>
                        {types.fields.map((field) => {
                            return <InputField key={field.id} name={`${types.value}[${field.slug}]`} defaultValue={field.value} required />
                        })}
                    </fieldset>
                })}
                <SubmitButton noOffset load={tinyloader2} title={t("Update")} action={updateForm} />
            </form>
        </AuthSection>
    )
}

export default SiteManage