import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { fetchData, validateForm } from '../../components/Helpers/Helper'
import { FORGOT_PASSWORD } from '../../components/Helpers/ApiRoutes'
import { UnAuthSection } from '../../components/Layouts/Layout'
import { UnAuthInputField, UnAuthSubmitButton } from '../../components/Helpers/FormHelper'
import { useTranslation } from "react-i18next"

const ForgotPassword = () => {
    const { t } = useTranslation();
    const [tinyloader, setTinyloader] = useState(false);
    const navegate = useNavigate();

    const submitForm = (e) => {
        if (validateForm(e)) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('forgot-password-form'));
            fetchData(FORGOT_PASSWORD, 'POST', formdata, false, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    navegate('/login');
                }
            })
        }
    }
    return (
        <UnAuthSection>
            <form className="p-4 p-sm-5 w-100 needs-validation" noValidate id="forgot-password-form">
                <h4 className="heading-lines mb-4 mt-0">{t("Forgot Password")}</h4>
                <UnAuthInputField name="email" placeholder={t("name@example.com")} required />
                <UnAuthSubmitButton  type="submit" load={tinyloader} title={t("Forgot Password")} action={submitForm} />
                <div className="d-flex justify-content-between pb-3">
                    <Link to="/login" className="anchor-blueorange fw-medium" title={t("Login")}>{t("Login")}</Link>
                </div>
            </form>
        </UnAuthSection>
    )
}

export default ForgotPassword
