import { useEffect, useState } from "react";
import { fetchData, initialFormState, validateForm } from "../../components/Helpers/Helper";
import { AuthSection } from "../../components/Layouts/Layout";
import { ModalInputField, ModalSubmitButton, SubmitButton, UnAuthInputField } from "../../components/Helpers/FormHelper";
import { ADD_LANGUAGE_FIELD, LANGUAGES } from "../../components/Helpers/ApiRoutes";
import { capitalize } from "lodash";
import { DeleteButton } from "../../components/Helpers/ButtonHelper";
import { ConfirmationModal, ModalSection } from "../../components/Helpers/ModalHelper";
import { useTranslation } from "react-i18next"

const LanguageManage = () => {
    const { t } = useTranslation();
    const [languages, setLanguages] = useState([])
    const [code, setCode] = useState('en')
    const [content, setContent] = useState(null)
    const [tinyloader, setTinyloader] = useState(false);
    const [tinyloader2, setTinyloader2] = useState(false);

    useEffect(() => {
        setContent(null)
        fetchData(`${LANGUAGES}?language=en`, 'GET', '', true, true, (res) => {
            if (res.success) {
                setCode(res.data.languages[0].code)
                setLanguages(res.data.languages)
                setContent(res.data.file_content)
            }
        })
    }, [])

    const resetForm = () => {
        initialFormState('create-language-Form')
    }

    const submitForm = (e) => {
        if (validateForm(e, "create-language-Form")) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById('create-language-Form'));
            fetchData(LANGUAGES, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    document.getElementById('closeLanguageModal').click()
                    setLanguages(res.data.languages)
                    setContent(res.data.file_content)
                }
            })
        }
    }

    const deleteLanguage = () => {
        fetchData(`${LANGUAGES}/${code}`, 'DELETE', '', true, true, (res) => {
            if (res.success) {
                setLanguages(res.data.languages)
                setContent(res.data.file_content)
            }
        })
    }

    const updateForm = (e) => {
        if (validateForm(e, `update-${code}-form`)) {
            setTinyloader2(true)
            let formdata = new FormData(document.getElementById(`update-${code}-form`));
            fetchData(`${LANGUAGES}/${code}`, 'POST', formdata, true, true, (res) => {
                setTinyloader2(false)
                if (res.success) {
                    setLanguages(res.data.languages)
                    setContent(res.data.file_content)
                }
            })
        }
    }

    const addForm = (e) => {
        if (validateForm(e, `add-${code}-form`)) {
            let formdata = new FormData(document.getElementById(`add-${code}-form`));
            fetchData(`${ADD_LANGUAGE_FIELD}/${code}`, 'POST', formdata, true, true, (res) => {
                if (res.success) {
                    initialFormState(`add-${code}-form`)
                    setLanguages(res.data.languages)
                    setContent(res.data.file_content)
                }
            })
        }
    }

    return (
        <AuthSection title="Language Manage" btn={<button type="button" className="btn btn-blue btn-sm px-3 mb-1 me-2" data-bs-toggle="modal" data-bs-target="#createLanguageModal" title={t("Create Language")}><i className="bi bi-plus-circle-fill me-1"></i> {t("Create Language")}</button>}>
            <div className="col-sm-12">
                <ul className="nav nav-tabs loading-data" id="myTab" role="tablist">
                    {languages.map((lang, key) => {
                        return <li key={key} className="nav-item" role="presentation">
                            <button className={`nav-link text-blue fw-medium ${(code === lang.code) ? "active" : ""}`} data-bs-toggle="tab" data-bs-target={lang.code} type="button" role="tab" data-tablename={lang.code} onClick={() => setCode(lang.code)}>{capitalize(lang.name)}</button>
                        </li>
                    })}
                </ul>
                <div className="tab-content border border-top-0">
                    <form id={`add-${code}-form`} action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                        <span className="fs-6 fw-medium text-blue">{t("Add New Field")}</span>
                        <div className="d-flex">
                            <input type="text" className="form-control me-2" name="key" placeholder="key" required autoComplete="off" />
                            <input type="text" className="form-control me-2" name="value" placeholder="value" required autoComplete="off" />
                            <button type="button" className={`btn btn-blue w-120px px-4 me-2`} title="Add" onClick={addForm}>Add</button>
                        </div>
                    </form>
                    <hr />
                    {languages.map((lang, key) => {
                        return <div key={key} className={`tab-pane fade p-3 show ${(code === lang.code) ? "active" : ""}`} id={lang.code} role="tabpanel">
                            <form id={`update-${lang.code}-form`} action="#" method="post" className="p-4 p-sm-5 needs-validation" noValidate>
                                {content && content[lang.code] && content[lang.code].map((item, key) => <div className="d-inline-flex mx-2" key={key}><UnAuthInputField name={item.key} defaultValue={item.value} required /></div>)}
                                <div className="d-flex">
                                    <SubmitButton noOffset={true} load={tinyloader2} title="UPDATE" action={updateForm} />
                                    {lang.code !== 'en1' && <div className="mt-2">
                                        <button type="button" className="btn btn-sm btn-outline-danger me-1 action-btn" data-bs-toggle="modal" data-bs-target="#languageDeleteModal"><i className="bi bi-trash-fill"></i></button>
                                    </div>}
                                </div>
                            </form>
                        </div>
                    })}
                </div>
            </div>
            <ModalSection title={t(`Add Language`)} modal_id="createLanguageModal" size="lg" close_btn_id="closeLanguageModal" action={resetForm}>
                <form action="#" method="post" className="needs-validation" id="create-language-Form" noValidate>
                    <ModalInputField name="code" required />
                    <ModalInputField name="name" required />
                    <ModalInputField type="file" name="image" required />
                    <ModalSubmitButton load={tinyloader} title={t("Create")} action={submitForm} />
                </form>
            </ModalSection>
            <ConfirmationModal modal_id="languageDeleteModal" title={t("Delete Language")} action={deleteLanguage}>
                <p className="">{t("Are you sure want to delete this language?")}</p>
            </ConfirmationModal>
        </AuthSection>
    )
}

export default LanguageManage