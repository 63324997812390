import { noImageHandle } from "../../../components/Helpers/Helper"
import { auth } from "./Initialize";

export const Header = () => {

    return (<>
        <div className="row border-bottom mb-2 pb-2">
            <div className="col-6">
                <b className="text-orange">{auth.currentUser.displayName}</b>
            </div>
            <div className="col-6 text-end">
                <img className="mmwh-35px ms-auto" src={auth.currentUser.photoURL} alt="Prolfile logo" onError={noImageHandle} />
            </div>
        </div>
    </>)
}