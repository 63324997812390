import { Section } from "../../components/Layouts/Layout";
import ManagePage from "../../components/Layouts/ManagePage";

const Home = () => {

    return (
        <Section>
            <ManagePage code={200} />
        </Section>
    )
}

export default Home