import { useEffect, useState } from "react";
import { query, collection, onSnapshot, limit, where } from "firebase/firestore";
import { noImageHandle } from "../../../components/Helpers/Helper";
import { auth, db } from "./Initialize";

export const Messages = ({ chatUser }) => {
    const [messages, setMessages] = useState([]);
    const [lodingText, setLodingText] = useState();

    useEffect(() => {
        if (chatUser) {
            setLodingText("Loading...");
            const q = query(collection(db, "userChats"), where("senderUid", "in", [auth.currentUser.uid, chatUser.uid]), where("receiverUid", "in", [auth.currentUser.uid, chatUser.uid]), limit(50));
            const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
                setLodingText("Your chat is empty.");
                let messages = [];
                QuerySnapshot.forEach((doc) => messages.push({ ...doc.data(), id: doc.id }));
                setMessages(messages);
                setTimeout(() => {
                    let scroll = document.querySelector("#messagesList")
                    scroll.scrollTop = scroll.scrollHeight;
                    scroll.behavior = "smooth"
                }, 0);
            });
            return () => unsubscribe
        }
    }, [chatUser]);
    return (<>
        <div className="overflow-auto mmh-600px" id="messagesList">
            {messages && messages.length ? messages.map((msg, key) => {
                return (<div key={key} className={`row ${msg.senderUid === auth.currentUser.uid && 'text-end'} border-bottom m-2 p-2`}>
                    <div className="">
                        <img className="w-35px" src={msg.avatar ?? ""} alt="user avatar" onError={noImageHandle} />
                        <span className="ms-2 text-muted">{msg.name}</span>
                    </div>
                    <p className="m-0">{msg.text}</p>
                    <p className="text-muted m-0">{msg.createdAt && msg.createdAt.seconds}</p>
                </div>)
            }) : <>
                <div className="text-center">
                    <img className="mmwh-200px" src={""} alt="user avatar" onError={noImageHandle} />
                    <p>{lodingText ? lodingText : "Please select a user."}</p>
                </div>
            </>}
        </div>
    </>)
}