import { AuthSection } from "../../components/Layouts/Layout"
import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { Users } from "./partials/Users";
import { Messages } from "./partials/Messages";
import { SendMessage } from "./partials/SendMessage";
import { UnAuthenticateSection } from "./partials/UnAuthenticateSection";
import { auth } from "./partials/Initialize";
import { Header } from "./partials/Header";

const Chat = () => {
    const [user] = useAuthState(auth);
    const [chatUser, setChatUser] = useState();

    const authAction = () => {
        setChatUser(null)
        if (user) auth.signOut();
        else {
            const provider = new GoogleAuthProvider();
            signInWithRedirect(auth, provider);
        }
    };

    return (
        <AuthSection title="Chat" btn={<button className={`btn btn-${user ? 'orange' : 'blue'} btn-sm px-3 me-2`} title={user ? "Sign-out" : "Sign-in"} onClick={authAction}><i className="bi bi-lock me-1"></i> {user ? "Sign-out" : "Sign-in"}</button>}>
            <style jsx="true">{`
            .mmh-600px{
                min-height: 600px;
                max-height: 600px;
            }
        `}</style>
            {user ? <>
                <Header />
                <div className="row mx-2">
                    <Users chatUser={chatUser} setChatUser={setChatUser} />
                    <div className="col-sm-8 py-2 border rounded">
                        <Messages chatUser={chatUser} />
                        <SendMessage chatUser={chatUser} />
                    </div>
                </div>
            </> : <>
                <UnAuthenticateSection />
            </>}
        </AuthSection>
    )
}

export default Chat
