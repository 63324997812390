import { GoogleAuthProvider, createUserWithEmailAndPassword, signInWithRedirect, signInWithEmailAndPassword, getAuth, updateProfile } from "firebase/auth";
import { useState } from "react";
import { showAlert } from "../../../components/Helpers/Helper";
import { doc, setDoc } from "firebase/firestore";
import { db } from "./Initialize";

export const UnAuthenticateSection = () => {
    const auth = getAuth();
    const [credentials, setCredentials] = useState({ name: '', email: '', password: '' });
    const [loginByCredentials, setLoginByCredentials] = useState(false);
    const [tinyloader, setTinyloader] = useState(false);

    const loginWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        signInWithRedirect(auth, provider);
    };

    const authActionByCredentials = async () => {
        setTinyloader(true)
        if (loginByCredentials === 'login') {
            signInWithEmailAndPassword(auth, credentials.email, credentials.password).then(async (res) => {
                // await setDoc(doc(db, "users", res.user.uid), { uid: res.user.uid, displayName: res.user.displayName, email: res.user.email, photoURL: res.user.photoURL });
            }).catch((error) => {
                setTinyloader(false)
                showAlert({ success: false, message: error.message })
            });
        } else {
            createUserWithEmailAndPassword(auth, credentials.email, credentials.password).then(async (res) => {
                await updateProfile(res.user, { displayName: credentials.name, photoURL: "https://lh3.googleusercontent.com/a/AAcHTtfYERs0_9a3W-hnQerMb7r3_FdOoqGYVuc2gzG8=s96-c" })
                await setDoc(doc(db, "users", res.user.uid), { uid: res.user.uid, displayName: credentials.name, email: credentials.email, photoURL: "https://lh3.googleusercontent.com/a/AAcHTtfYERs0_9a3W-hnQerMb7r3_FdOoqGYVuc2gzG8=s96-c" });
            }).catch((error) => {
                setTinyloader(false)
                showAlert({ success: false, message: error.message })
            });
        }
    };

    return (<>
        <p>Hello!  Welcome to Chat. You need to <u className="text-info" role="button" onClick={loginWithGoogle}>sign-in with google</u> or <u className="text-info" role="button" onClick={() => setLoginByCredentials("login")}>sign-in with email & password</u> first.</p>
        {loginByCredentials && <>
            <div className="d-flex">
                {loginByCredentials === 'register' && <input type="text" className="form-control me-2" name="name" placeholder="Enter your Name" required autoComplete="off" onChange={(e) => setCredentials(prev => ({ ...prev, name: e.target.value }))} />}
                <input type="text" className="form-control me-2" name="email" placeholder="Enter your Email" required autoComplete="off" onChange={(e) => setCredentials(prev => ({ ...prev, email: e.target.value }))} />
                <input type="text" className="form-control me-2" name="password" placeholder="Enter your Password" required autoComplete="off" onChange={(e) => setCredentials(prev => ({ ...prev, password: e.target.value }))} />
                <button disabled={tinyloader} type="button" className={`btn btn-blue w-120px px-4 me-2`} title={loginByCredentials === 'login' ? "Login" : "Register"} onClick={authActionByCredentials}>{loginByCredentials === 'login' ? "Login" : "Register"}</button>
            </div>
            {loginByCredentials === 'register' ? <p>We have an accont. <u className="text-info" role="button" onClick={() => setLoginByCredentials("login")}>Login</u></p> :
                <p>We don't have any accont. <u className="text-info" role="button" onClick={() => setLoginByCredentials("register")}>Register</u></p>}
        </>}
    </>)
}