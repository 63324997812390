import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header";
import Aside from "./Aside";
import { useLayoutEffect } from "react";
import FrontendHeader from "./FrontendHeader";
import FrontendFooter from "./FrontendFooter";
import { scrollbarSetToTop } from "../Helpers/Helper";
import { useTranslation } from "react-i18next"

export const Layout = (props) => {
    const navigate = useNavigate()

    useLayoutEffect(() => {
        scrollbarSetToTop()
        let token = localStorage.getItem('adb-token')
        if (props.redirect) {
            if (token && !props.authorized) {
                navigate('/dashboard')
            } else if (!token && props.authorized) {
                navigate('/login')
            }
        }
    }, [navigate, props]);

    return (
        <>
            {props.header && <Header />}
            {props.front && <FrontendHeader />}
            {props.aside && <Aside />}
            <Outlet />
            {props.front && <FrontendFooter />}
        </>
    )
}

const Section = ({ children }) => {

    return (
        <section className="">
            {children}
        </section>
    )
}

const AuthSection = ({ title, children, btn }) => {

    return (
        <section className="admin-wrapper">
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col mb-3">
                        <h6 className="m-0 heading-lines fw-medium pb-2">{title}</h6>
                    </div>
                    <div className="col-auto mb-2">{btn}</div>
                </div>
                <div className="row g-2 g-sm-3 g-lg-4">
                    <div className="col-sm-12">
                        <div className="card border-0 shadow-sm rounded-10">
                            <div className="card-body">{children}</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const UnAuthSection = ({ children }) => {
    const { t } = useTranslation();

    return (
        <section className="login-section">
            <div className="container">
                <div className="row g-0 rounded-10 shadow overflow-hidden">
                    <div className="col-md-6 col-lg-5 bg-white d-flex align-items-center">
                        {children}
                    </div>
                    <div className="col-md-6 col-lg-7 d-flex align-items-center bg-lightblue">
                        <img src="/images/logo.png" className="w-100" alt={t("Logo")} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Layout;
export { UnAuthSection, AuthSection, Section }